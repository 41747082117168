import AppHome from './components/AppHome'
import BustaPesa from "./components/BustaPesa";

const Home = () => import('./components/Home')
const Sport = () => import('./components/Sport.vue')
const SportsBook = () => import('./components/SportsBook')

const FixturesBySport = () => import('./components/FixturesBySport')
const Esport = () => import('./components/Esport')
const Print = () => import('./components/Print')
const PrintGame = () => import('./components/PrintGame')
const Tips = () => import('./components/Tips')
const Sharebet = () => import('./components/Sharebet')
const Offers = () => import('./components/Offers')
const Live = () => import('./components/Live')
const MobileApp = () => import("./components/MobileApp");
const Match = () => import('./components/Match')
const AviatorLogin = () => import('./components/AviatorLogin')
const Login = () => import('./components/Login')
const Join = () => import('./components/Join')
const Reset = () => import('./components/Reset')
const Deposit = () => import('./components/Deposit')
const DepositHowTo = () => import('./components/DepositHowTo')
const Setting = () => import('./components/Setting')
const Bets = () => import('./components/Bets')
const MyBets = () => import('./components/MyBets')
const Terms = () => import('./components/Terms')
const Faq = () => import('./components/Faq')
const Freebet = () => import("@/components/Freebet");
const Search = () => import("./components/Search");
const Countries = () => import("@/components/Countries");
const Outright = () => import("@/components/outright/Index");
const Today = () => import("@/components/Today");
const Tomorrow = () => import("@/components/Tomorrow");
const Upcoming = () => import("@/components/Upcoming");
const Share = () => import("@/components/Share");
const Invite = () => import("@/components/Invite");
const Help = () => import("./components/Help");
const Responsible = () => import("./components/Responsible");
const Booklet = () => import("./components/Booklet");
const Jackpot = () => import("./components/jackpot/Jackpot");
const Results = () => import("./components/Results");
const Livescore = () => import("./components/Livescore");
const Profile = () => import("./components/Profile");
const Gift = () => import("./components/Gift");
const SelfExclusion = () => import("./components/SelfExclusion");
const FormulaOne = () => import("./components/FormulaOne");

//casino menus
const CasinoGames = () => import("./components/casino/CasinoGames");
const Casino = () => import("./components/casino/Casino");
const Slot = () => import("./components/casino/Slot");
const Virtuals = () => import("./components/casino/Virtuals");
const LiveCasino = () => import("./components/casino/Live");
const Fastballs = () => import("./components/casino/Fastballs");
const Smartsoft = () => import("./components/casino/Smartsoft");
const Jetsafi = () => import("./components/casino/Jetsafi");
const Booming = () => import("./components/casino/Booming");
const Stp = () => import("./components/casino/Stp");
const Onlyplay = () => import("./components/casino/Onlyplay");

//
const Rocketman = () => import("./components/casino/Rocketman");
const MonkeyBizniz = () => import("./components/casino/MonkeyBizniz");
const Aviator = () => import("./components/casino/Aviator");
const Comet = () => import("./components/casino/Comet");
const JetX = () => import("./components/casino/JetX");
const FootballX = () => import("./components/casino/FootballX");
const StreetDice = () => import("./components/casino/StreetDice");
const Spin2Win = () => import("./components/casino/Spin2Win");
const AztecGems = () => import("./components/casino/AztecGems");
const Plinko = () => import("./components/casino/Plinko");
export const routes = [
    
    {
        path: '/crash',
        name: 'home',
        component: Home,
        meta: {
            title: 'qwerty - Your Ultimate Betting Destination',
            ogTitle: 'qwerty - Your Ultimate Betting Destination',
            description: 'Welcome to qwerty, the ultimate online betting platform. Enjoy top-notch sports betting, exciting casino games, live betting options, and more!',
            ogDescription: 'Welcome to qwerty, the ultimate online betting platform. Enjoy top-notch sports betting, exciting casino games, live betting options, and more!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/a'
        }
    },
    {
        path: '/',
        name: 'sport',
        component: Sport,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/'
        }
    },
    {
        path: '/sportsbook',
        name: 'sportsbook',
        component: SportsBook,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/'
        }
    },
    {
        path: "/sports/football",
        name: "football",
        component: Home,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/a'
        }
    },
    {
        path: '/sport/:sport',
        name: 'sportSelect',
        component: Sport,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/sport/:sport'
        }
    },
    {
        path: '/a',
        name: 'apphome',
        component: AppHome,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/a'
        }
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/search'
        }
    },
    {
        path: '/sports/:sport_name/:sport_id',
        name: 'fixturesbysport',
        component: FixturesBySport,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/sports/:sport_name/:sport_id'
        }
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
        meta: {
            title: 'qwerty Sports - Bet on a Wide Range of Sports',
            ogTitle: 'qwerty Sports - Bet on a Wide Range of Sports',
            description: 'Bet on your favorite sports with qwerty. From football to basketball, enjoy a comprehensive range of sports betting options with competitive odds and exciting promotions.',
            ogDescription: 'Bet on your favorite sports with qwerty. From football to basketball, enjoy a comprehensive range of sports betting options with competitive odds and exciting promotions.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/live'
        }
    },
    {
        path: '/freebet',
        name: 'freebet',
        component: Freebet,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/'
        }
    },
    {
        path: '/today',
        name: 'today',
        component: Today,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/today'
        }
    },

    {
        path: '/tomorrow',
        name: 'tomorrow',
        component: Tomorrow,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/tomorrow'
        }
    },

    {
        path: '/upcoming',
        name: 'upcoming',
        component: Upcoming,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/upcoming'
        }
    },
    {
        path: '/esport',
        name: 'esport',
        component: Esport,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/esport'
        }
    },
    {
        path: "/match/:status/:game_id/:name",
        name: "match",
        component: Match,
    },
    {
        path: '/share/:code',
        name: 'share',
        component: Share,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke//share/:code'
        }
    },
    {
        path: '/invite/:code',
        name: 'invite',
        component: Invite,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/invite/:code'
        }
    },
    {
        path: '/join',
        name: 'join',
        component: Join,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/join'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/login'
        }
    },
    {
        path: '/aviatorlogin',
        name: 'aviatorlogin',
        component: AviatorLogin,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/login'
        }
    },
    {
        path: '/print',
        name: 'print',
        component: Print,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/print'
        }
    },

    {
        path: '/print-game',
        name: 'print-game',
        component: PrintGame,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/print-game'
        }
    },
    {
        path: '/deposit',
        name: 'deposit',
        component: Deposit,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/deposit'
        }
    },
    {
        path: '/countries',
        name: 'country',
        component: Countries,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/countries'
        }
    },
    {
        path: '/outrights/:type',
        name: 'outright',
        component: Outright,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/outrights/:type'
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/setting'
        }
    },
    {
        path: '/my-account',
        name: 'my-account',
        component: Setting,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/my-account'
        }
    },
    {
        path: '/my-bets',
        name: 'bets',
        component: Bets,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/my-bets'
        }
    },
    {
        path: '/my-profile-bets',
        name: 'mybets',
        component: MyBets,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke//my-profile-bets'
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        meta: {
            title: 'qwerty Terms and Conditions - Legal Information',
            ogTitle: 'qwerty Terms and Conditions - Legal Information',
            description: 'Read qwerty\'s Terms and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our terms before using our services.',
            ogDescription: 'Read qwerty\'s Terms and Conditions to understand the legal agreements and policies governing the use of our online betting platform. Ensure you are informed about our terms before using our services.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/terms'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {
            title: 'qwerty FAQs - Frequently Asked Questions',
            ogTitle: 'qwerty FAQs - Frequently Asked Questions',
            description: 'Find answers to frequently asked questions about qwerty\'s betting services. Get help with common queries and learn more about our platform\'s features and policies.',
            ogDescription: 'Find answers to frequently asked questions about qwerty\'s betting services. Get help with common queries and learn more about our platform\'s features and policies.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/faq'
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: Gift,
        meta: {
            title: 'qwerty Promotions - Exciting Bonuses and Offers',
            ogTitle: 'qwerty Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on qwerty Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on qwerty Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/gifts'
        }
    },
    {
        path: '/selfexclusion',
        name: 'selfexclusion',
        component: SelfExclusion,
        meta: {
            title: 'qwerty Promotions - Exciting Bonuses and Offers',
            ogTitle: 'qwerty Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on qwerty Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on qwerty Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/gifts'
        }
    },
    {
        path: '/deposit-how-to',
        name: 'deposit-how-to',
        component: DepositHowTo,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/deposit-how-to'
        }
    },
    {
        path: '/mobile-app',
        name: 'mobile-app',
        component: MobileApp,
    },
    {
        path: '/howto',
        name: 'help',
        component: Help,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/howto'
        }
    },
    {
        path: '/responsible-gaming',
        name: 'responsible',
        component: Responsible,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/responsible-gaming'
        }
    },
    {
        path: '/reset/:msisdn/:code',
        name: 'reset',
        component: Reset,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/reset/:msisdn/:code'
        }
    },
    {
        path: '/booklet',
        name: 'booklet',
        component: Booklet,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/booklet'
        }
    },
    {
        path: '/jackpot-games',
        name: 'jackpot',
        component: Jackpot,
        meta: {
            title: 'qwerty Jackpot - Win Big with Our Jackpot Games',
            ogTitle: 'qwerty Jackpot - Win Big with Our Jackpot Games',
            description: 'Try your luck with qwerty Jackpot games. Discover a range of jackpot slots and games with huge prizes and the chance to win big. Join now for exciting opportunities!',
            ogDescription: 'Try your luck with qwerty Jackpot games. Discover a range of jackpot slots and games with huge prizes and the chance to win big. Join now for exciting opportunities!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/jackpot-games'
        }
    },
    {
        path: '/tips',
        name: 'tips',
        component: Tips,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/tips'
        }
    },
    {
        path: '/sharebet',
        name: 'sharebet',
        component: Sharebet,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/sharebet'
        }
    },
    {
        path: '/qwerty',
        name: 'qwerty',
        component: BustaPesa,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/qwerty'
        }
    },
    {
        path: '/formula-1',
        name: 'formula1',
        component: FormulaOne,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/formula-1'
        }
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers,
        meta: {
            title: 'qwerty Promotions - Exciting Bonuses and Offers',
            ogTitle: 'qwerty Promotions - Exciting Bonuses and Offers',
            description: 'Discover the latest promotions and bonuses on qwerty Get free bets, cashback, and more. Don\'t miss out!',
            ogDescription: 'Discover the latest promotions and bonuses on qwerty Get free bets, cashback, and more. Don\'t miss out!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/offers'
        }
    },
    {
        path: '/results',
        name: 'results',
        component: Results,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/results'
        }
    },
    {
        path: '/livescore',
        name: 'livescore',
        component: Livescore,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/livescore'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/profile'
        }
    },
    {
        path: '*',
        name: '404',
        component: Home,
        meta: {
            title: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            ogTitle: 'qwerty - Kenya\'s Best Online Sports Betting Platform',
            description: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            ogDescription: 'Experience the thrill of sports betting with qwerty.  Join now for the best odds, live betting, and exciting promotions. Bet responsibly.',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/404'
        }
    },
    //    casino games
    {
        path: '/casino',
        name: 'casino',
        component: Casino,
        meta: {
            title: 'qwerty Casino - Play Exciting Casino Games and Slots',
            ogTitle: 'qwerty Casino - Play Exciting Casino Games and Slots',
            description: 'Explore qwerty Casino for a thrilling experience with a variety of games including slots, poker, and table games. Discover exciting promotions and bonuses for endless fun!',
            ogDescription: 'Explore qwerty Casino for a thrilling experience with a variety of games including slots, poker, and table games. Discover exciting promotions and bonuses for endless fun!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/casino'
        }
    },
    //    casino games
    {
        path: '/slot',
        name: 'slot',
        component: Slot,
        meta: {
            title: 'qwerty Casino - Play Exciting Casino Games and Slots',
            ogTitle: 'qwerty Casino - Play Exciting Casino Games and Slots',
            description: 'Explore qwerty Casino for a thrilling experience with a variety of games including slots, poker, and table games. Discover exciting promotions and bonuses for endless fun!',
            ogDescription: 'Explore qwerty Casino for a thrilling experience with a variety of games including slots, poker, and table games. Discover exciting promotions and bonuses for endless fun!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/casino'
        }
    },
    {
        path: '/virtuals',
        name: 'virtuals',
        component: Virtuals,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/virtuals'
        }
    },
    {
        path: '/casino/live',
        name: 'livecasino',
        component: LiveCasino,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/casino/live'
        }
    },
    {
        path: '/casino-games',
        name: 'casinogames',
        component: CasinoGames,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/casino-games'
        }
    },
    {
        path: '/rocketman',
        name: 'rocketman',
        component: Rocketman,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/rocketman'
        }
    },
    {
        path: '/monkeybizniz',
        name: 'monkeybizniz',
        component: MonkeyBizniz,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/rocketman'
        }
    },
    {
        path: '/aviator',
        name: 'aviator',
        component: Aviator,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/aviator'
        }
    },
    {
        path: '/comet',
        name: 'comet',
        component: Comet,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/comet'
        }
    },
    {
        path: '/jetx',
        name: 'jetx',
        component: JetX,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/jetx'
        }
    },
    {
        path: '/footballx',
        name: 'footballx',
        component: FootballX,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/footballx'
        }
    },
    {
        path: '/streetdice',
        name: 'streetdice',
        component: StreetDice,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/streetdice'
        }
    },
    {
        path: '/spin2win',
        name: 'spin2win',
        component: Spin2Win,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/spin2win'
        }
    },
    {
        path: '/aztecgems',
        name: 'aztecgems',
        component: AztecGems,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/aztecgems'
        }
    },
    {
        path: '/plinko',
        name: 'plinko',
        component: Plinko,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/plinko'
        }
    },
    {
        path: '/fastballs',
        name: 'fastballs',
        component: Fastballs,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/fastballs'
        }
    },
    {
        path: '/smartsoft',
        name: 'smartsoft',
        component: Smartsoft,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/smartsoft'
        }
    },
    {
        path: '/jetsafi',
        name: 'jetsafi',
        component: Jetsafi,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/jetsafi'
        }
    },
    {
        path: '/stp',
        name: 'stp',
        component: Stp,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/stp'
        }
    },
    {
        path: '/booming',
        name: 'Booming',
        component: Booming,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/booming'
        }
    },
    {
        path: '/onlyplay',
        name: 'Onlyplay',
        component: Onlyplay,
        meta: {
            title: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            ogTitle: 'qwerty Sports Betting - Bet on Your Favorite Sports',
            description: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            ogDescription: 'Bet on football, basketball, rugby, and more with qwerty.  Enjoy live betting and win big. Sign up today!',
            keywords: 'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting, Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In-play betting, Cash out, Live odds, Betting tips, Aviator, Jetx, Cometcrash, Spin2win, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support, Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
            ogImage: '',
            ogUrl: 'https://bahatibet.waliliana.co.ke/onlyplay'
        }
    },

];