<template>
  <footer
    style="background-color: var(--darkBackground)"
    class="text-lg-start bg-body-tertiary text-muted mt-4"
  >
    <section class="">
      <div class="container text-md-start mt-4">
        <div style="margin-left: 10px; text-align: center" class="mt-4">
          <router-link to="/my-account">
            <h5 style="font-weight: 800; color: #face00" class="">
              <span style="font-weight: 500; color: white">DEPOSIT</span>
              <br />
              MPESA PAYBILL: ******
            </h5>
          </router-link>
          <p style="font-weight: 500; color: white" class="">
            <img style="width: 40px" src="/img/BottomNav/18.png" />
            <br />
            Play Responsibly
            <br />
            Not for persons under the age of 18 years.
          </p>
          <p style="font-size: 14px" class="text-light">
            <a href="tel:0716797777" class="contact-link">
              <span style="color: white">
                <img
                  style="width: 14px"
                  src="/img/home/iphone.png"
                  alt="Call qwerty"
                />
                : Phone No.
              </span>
            </a>
            <a href="mailto:help@qwerty.com" class="contact-link">
              <span style="color: white">
                <img
                  style="width: 14px"
                  src="/img/home/email.png"
                  alt="Email qwerty"
                />
                : email
              </span>
            </a>
          </p>
        </div>
        <div style="margin-left: 10px; text-align: center" class="p-1">
          <p class="text-light" v-html="companyInfo"></p>
        </div>
      </div>
    </section>

    <div class="liii text-center">
      <a
        href="#"
        class="footer-link title-text p-2 text-light"
      >
        <img
          style="width: 25px"
          src="/img/home/facebook.png"
          alt="qwerty facebook"
        />
      </a>
      <a
        href="#"
        class="footer-link title-text p-2 text-light"
      >
        <img style="width: 25px" src="/img/home/x.png" alt="qwerty x" />
      </a>
      <a
        href="#"
        class="footer-link title-text p-2 text-light"
      >
        <img
          style="width: 25px"
          src="/img/home/instagram.png"
          alt="qwerty instagram"
        />
      </a>
      <a
        href="#"
        class="footer-link title-text p-2 text-light"
      >
        <img
          style="width: 25px"
          src="/img/home/tiktok.png"
          alt="qwerty tiktok"
        />
      </a>
      <a
        href="#"
        target="_blank"
        class="footer-link title-text p-2 text-light"
      >
        <img
          style="width: 25px"
          src="/img/home/whatsapp.png"
          alt="qwerty whatsapp"
        />
      </a>
    </div>
    <div
      class="text-center p-4 text-light"
      style="background-color: rgba(0, 0, 0, 0.05)"
    >
      © Copyright: qwerty. All rights reserve
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      companyInfo: process.env.VUE_APP_COMPANY_INFO
    };
  }
};
</script>

<style scoped></style>
